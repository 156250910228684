/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._uAuth = function () {};

novelAjax._uAuth.prototype.login = function(opt){
    var url = '/ajax_login';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uAuth.prototype.register = function(opt){
    var url = '/ajax_register';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax.uAuth = new novelAjax._uAuth();
