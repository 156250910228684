/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._uInfo = function () {};

novelAjax._uInfo.prototype.getInfo = function(opt){
    var url='/user/profile';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uInfo.prototype.changePW = function(opt){
    var url='/user/password';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uInfo.prototype.changeAvatar = function(opt){
    var url='/user/avatar';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax.uInfo = new novelAjax._uInfo();