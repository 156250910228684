novelJS.details={
    init:function(){
        novelJS.details.changetab(); 
    },
    changetab:function(){
        let $novelId=$(".details-con .details-conrig .details-conrig-table").attr('data');
        let str=""
        novelAjax.other.getChapList({
            novel_id : $novelId,
            success: function (res) {
                var data=res.data.data;
                if (res.data.code==200){
                    $(".details-con .details-conrig .details-conrig-tablecon ul").html('');
                    for (var i=0;i<data.length;i++){
                        str += '<li>';
                        str += "<span>"+data[i].chapter_number+"."+"</span>"+"<a href='"+data[i].path+"' title='"+data[i].chapter_title+"'>"+data[i].chapter_title+"</a>";
                        str += "</li>"
                    }
                    $(".details-con .details-conrig .details-conrig-tablecon ul").append(str);
                }
            },
            error: function (res) {
                 console.log(res);
            }
        });
        if($('.details-lr .details-bookfl .faker').hasClass('coming-b')){
            $('.details-conrig-tab .details-conrig-table').css('display','none');
            $('.details-read').addClass('locking');
            
        }else{
            $('.details-conrig-tab .details-conrig-table').css('display','');
        }
        $('.details-con .details-conrig .details-conrig-tab .details-conrig-table').click(function(){
            $('.details-con').addClass('details-conrig-tabadd');
        });
        $('.details-con .details-conrig .details-conrig-tab .details-conrig-about').click(function(){
            $('.details-con').removeClass('details-conrig-tabadd')
        })
    },
    shareBook:function(el){
        var $el=$(el);
        var url=location.href;
        var title= $('.details-bookfr .novelnamehid').text();
        $el.find('.sharet').on('click',function () {
            window.open('https://twitter.com/intent/tweet?'
                + '&text=' + encodeURIComponent(title)
                + '&url='  + encodeURIComponent(url)
                + '&via=Flying_Lines',
                'sharer',
                'width=626,height=436');
        });
        $el.find('.sharef').on('click',function () {
            window.open('https://www.facebook.com/sharer/sharer.php?'
                +'u=' + encodeURIComponent(url)
                +'&t='+ encodeURIComponent(title),
                'sharer',
                'width=626,height=436');
        });
        $el.find('.sharep').on('click',function () {
            var imgUrl=$(this).data('img-src');
            // console.log(imgUrl)
            window.open('https://www.pinterest.com/pin/create/bookmarklet/?url=' + encodeURIComponent(url)
                + '&media=' + encodeURIComponent(imgUrl)
                + '&description='+ encodeURIComponent(title),
                'sharer',
                'width=626,height=436');
        });

        $el.find('.sharer').on('click',function () {
            window.open('https://www.reddit.com/submit?url=' + encodeURIComponent(url)
                + '&title='+ encodeURIComponent(title),
                'sharer',
                'width=1000,height=500');
        });
    },

}