/**
 * Created by Administrator on 2018/7/23.
 */


novelJS.common = {
    init: function () {
    },

    isLogin: function() {

    },

    changeURL:function (url, title, state) {
        var state = state || null;
        var title = title || null;

        if(!url || !window.history.replaceState) return;
        window.history.replaceState(state,title,url);
    },

    fingerprint : function () {
        getCookie();
        function getCookie() {
            var strcookie = document.cookie;
            var arrcookie = strcookie.split("; ");
            for ( var i = 0; i < arrcookie.length; i++) {
                var arr = arrcookie[i].split("=");
                if (arr[0] == name){
                    return arr[1];
                }
                if (arr[0]=='Fingerprint'){
                    return;
                }else {
                    setCookie();
                    return;
                }
            }
        }

        // console.log($.cookie('Fingerprint'));

        function setCookie() {
            var fp1 = new Fingerprint({screen_resolution: true}).get();
            // console.log(fp1);
            var setCookie = function (name, value, day) {
                if(day !== 0){
                    /* When the set time is equal to 0, the expires attribute is not set, and the cookie
                     is deleted after the browser is closed */
                    var expires = day * 24 * 60 * 60 * 1000;
                    var date = new Date(+new Date()+expires);
                    document.cookie = name + "=" + value + ";expires=" + date.toUTCString();
                }else{
                    document.cookie = name + "=" + value;
                }
            };

            setCookie('Fingerprint',fp1,10);
        }
    },

    setTitle: function (title) {
        document.title= title;
    },

    //set cookie
    setCookie: function(c_name,value,expireDays) {
        var Days =expireDays || 10;
        var exDate=new Date();
        exDate.setTime(exDate.getTime()+Days*24*60*60*1000);
        document.cookie=c_name+ "=" +escape(value)+";expires="+exDate.toGMTString();
    },

    //get cookie
    getCookie: function (c_name) {
        var c_start,c_end;
        if (document.cookie.length>0)
        {
            c_start=document.cookie.indexOf(c_name + "=");
            if (c_start!=-1)
            {
                c_start=c_start + c_name.length+1;
                c_end=document.cookie.indexOf(";",c_start);
                if (c_end==-1) c_end=document.cookie.length;
                return unescape(document.cookie.substring(c_start,c_end));
            }
        }
        return false
    }

};