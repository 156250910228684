novelJS.read={
    init:function(){
        novelJS.read.chanConBac();
        novelJS.read.chanBotBac();
        novelJS.read.getChapterOl();
        novelJS.read.getClickChapter();
        novelJS.read.changeOl();
        novelJS.read.checkWindowSiz();
        novelJS.read.disableCopy();
        novelJS.read.foldNav();

    },
    chanConBac:function(){
        
        let flag=false;
        let $botbac=$('.read-content .read-contentr .read-contentr-botbac');
        $('.read-content .read-contentr .read-contentr-set').click(function(){
            if(!flag){
                flag=true;
                $botbac.css('opacity','1');
                $(this).removeClass('read-contentr-set').addClass('read-contentr-err');
            }else{
                $(this).removeClass('read-contentr-err').addClass('read-contentr-set');
                flag=false;
                $botbac.css('opacity','0')
            }
        })
    },
    chanBotBac:function(){
        $('.read-content .botcirw').addClass('botcirwbor');
        
        $('.read-contentr .botciry').click(function(){
            $('.read-content .botcirw').removeClass('botcirwbor');
            $('body').addClass('atproeyes').removeClass('atnight');
            novelJS.read.loading();
            
        });
        $('.read-contentr .botcirw').click(function(){
            $('.read-content .botcirw').addClass('botcirwbor')
            $('body').removeClass('atnight').removeClass('atproeyes');
            novelJS.read.loading();
        })
        $('.read-contentr .botcirb').click(function(){
            $('.read-content .botcirw').removeClass('botcirwbor')
            $('body').addClass('atnight').removeClass('atproeyes');
            novelJS.read.loading(); 
        })
        var fontSize=18
        $('.read-contentr .read-contentr-botbac .botbackcon .botbackad').click(function(){
            fontSize++;
            $('.read-contentr-HC p').css('font-size',fontSize+"px");
            $('.botbackbumb').html(fontSize)
        })
        $('.read-contentr .read-contentr-botbac .botbackcon .botbackre').click(function(){
            fontSize--;
            $('.botbackbumb').html(fontSize)
            $('.read-contentr-HC p').css('font-size',fontSize+"px");
        })
    },
    getChapterOl:function(){
        
        let $novelId=$('.read-content .read-contentl .readlistnum .readolnum').attr('data');
        let str="";
        novelAjax.other.getChapList({
            novel_id : $novelId,
            success: function (res) {
                var data=res.data.data;
                
                if (res.data.code==200){
                    for (var i=0;i<data.length;i++){
                        str += '<li>';
                        str += "<span data="+data[i].id+">"+data[i].chapter_number+"</span>"+"<a href='javascript:;' title='"+data[i].chapter_title+"'>"+data[i].chapter_title+"</a>";
                        str += "</li>"
                    }
                    $('.read-content .read-contentl .readlistnum .readolnum ol').append(str);
                    $('.read-contentl .small-nav .chapter-list ul').append(str);
                    $('.read-content .read-contentl .readlistnum .readolnum ol li span').map(function(val){
                        // console.log($(".read-content .read-contentr .read-contentrchapter-na span").html())
                        if($(this).html()==$(".read-content .read-contentr .read-contentrchapter-na span").html()){
                            $(this).parent().css({'color':'#369f9a'});
                        }
                    })
                    // novelJS.read.status.preid=$('.read-contentl .readolnum ol li').eq(novelJS.read.status.$chapter-1).find('span').attr('data');
                    // console.log(novelJS.read.status.preid)
                }
            },
            error: function (res) {
                 console.log(res);
            }
        })
        
    },
    getClickChapter(){
        let $chapterId=$(".read-content .read-contentr .read-contentr-HC").attr('data');
        var nextId;
        
        $.ajax({
            type:'get',
            url:"/chapter/"+$chapterId,
            dataType:'json',
            success:function(res){
                let data=res.data.chapter_content;
                nextId=res.data.nextChapterId;
                $(".read-content .read-contentr .read-contentr-HC").append(data);

                
                // if(res.data.chapter_number>5)
                //$(".read-content .read-contentr .read-contentr-HC").append('<div class="ellipsis">......</div><div class="drainage"><span>Click the button to read more on NovelSpread.</span><a href="http://www.novelspread.com'+res.data.path+'"><div>Read Now</div></a></div>'+`</div>`);
                $(".read-content .read-contentr .read-contentrchapter-na").append('<span>'+res.data.chapter_number+'</span>');
                $(".read-content .read-contentr .read-contentrchapter-nafon").append(res.data.chapter_title);
                $(".read-content .read-contentr .read-contentr-transla").html('<span>Translator: '+res.data.translator +'</span>'+
                    '<span>Updated on: '+res.data.created_at +'</span>');
                novelJS.read.scrollLoad(nextId);
                novelJS.common.setTitle(res.data.meta_title);
            },
            error:function (res) {
            }
        });
        
    },
    changeOl:function(){ 
        $(' .read-contentl .readlistnum .readolnum ol, .small-nav .chapter-list').on('click','li',function(){
            $(this).css('color','#369f9a').siblings().css('color','#b0afae');
            novelJS.read.status.allowGetChapter=false;
            var $curid=$(this).find('span').attr('data');
            $(".read-content .read-contentr .read-contentr-HC").remove();
            $(".read-content .read-contentr .read-contentrchapter-na").remove();
            $(".read-content .read-contentr .read-contentrchapter-nafon").remove();
            $(".read-content .read-contentr .read-contentr-transla").remove();
            $.ajax({
                type:'get',
                url:"/chapter/"+$curid,
                dataType:'json',
                success:function(res){
                    let valnext=res.data.nextChapterId;
                    let _chapternumber=res.data.chapter_number;
                    let str='<div class="read-contentrchapter-na">'+"Chapter "+res.data.chapter_number+'</div>'+
                        '<div class="read-contentrchapter-nafon" >'+res.data.chapter_title+'</div>'+
                        '<div class="read-contentr-transla">'+
                        '<span>Translator: '+res.data.translator +'</span>'+
                        '<span>Updated on: '+res.data.created_at +'</span>'+
                        '</div><div class="read-contentr-HC" data="'+res.data.id+'">'+ res.data.chapter_content
                    // console.log(_chapternumber)
                    // if(_chapternumber>5){
                    //     str+='<div class="ellipsis">......</div><div class="drainage"><span>Click the button to read more on NovelSpread.</span><a href="http://www.novelspread.com'+res.data.path+'"><div>Read Now</div></a></div>'+`</div>`
                    // }
                    $(".read-content .read-contentr").append(str);
                    novelJS.common.changeURL(res.data.path,res.data.chapter_title+'-Flying-lines');
                    novelJS.common.setTitle(res.data.meta_title);
                    novelJS.read.status.neid=valnext;
                    novelJS.read.status.allowGetChapter=true;
                },
                error:function (res) {     
                }
            }) 
        })
    },
    loading: function () {
        var $img;
        if ($('body').hasClass('atproeyes')){
            $img='yellow.gif';            
        }else if($('body').hasClass('atnight')){
            $img='black.gif';
        }else{
            $img='white.gif';
        }
        var str = "";
        str +=`<div class="loading">`;
        str +="   <img src='/build/images/global/"+$img+"' class='img-responsive'>";
        str +="</div>";
        if($(".read-content .read-contentr .read-contentr-HC div").hasClass('loading')){
            $(".read-content .read-contentr .read-contentr-HC .loading").remove();
            $(".read-content .read-contentr .read-contentr-HC:last").append(str);
        }else{
            $(".read-content .read-contentr .read-contentr-HC:last").append(str);
        } 
    },
    status:{
        allowGetChapter: true,
        neid:'',
        preid:'',
        $chapter:''
    },
    scrollLoad:function(e){
        novelJS.read.status.neid=e;
        $(window).scroll(function(){
            let doheight=$(document).height();
            let winheight=$(window).height();
            let winscrhei=$(window).scrollTop();
            if(novelJS.read.status.allowGetChapter){
                if((doheight-winheight)-winscrhei<=35&&winscrhei>0){
                    novelJS.read.status.allowGetChapter=false;
                    if(typeof(novelJS.read.status.neid)=='number'){
                        novelJS.read.loading();
                        $('.read-content .read-contentl .readlistnum .readolnum ol li span').map(function(){
                            if($(this).attr('data')==novelJS.read.status.neid){
                                $(this).parent().css({'color':'#369f9a'}).siblings().css({'color':'#b0afae'});
                                setTimeout(function(){
                                    $(".read-content .read-contentr .read-contentr-HC .loading").remove(); 
                                    $.ajax({
                                        type:'get',
                                        url:"/chapter/"+novelJS.read.status.neid,
                                        dataType:'json',
                                        success:function(res){
                                            let str='<div class="read-contentrchapter-na">'+"Chapter "+res.data.chapter_number+'</div>'+
                                                '<div class="read-contentrchapter-nafon" >'+res.data.chapter_title+'</div>'+
                                                '<div class="read-contentr-transla">'+
                                                '<span>Translator: '+res.data.translator +'</span>'+
                                                '<span>Updated on: '+res.data.created_at +'</span>'+
                                                '</div><div class="read-contentr-HC" data="'+res.data.id+'">'+ res.data.chapter_content
                                            // console.log(res.data.chapter_number)
                                            // if(res.data.chapter_number>5){
                                            //     str+='<div class="ellipsis">......</div><div class="drainage"><span> Click the button to read more on NovelSpread.</span><a href="http://www.novelspread.com'+res.data.path+'"><div>Read Now</div></p></div>'+`</div>`
                                            // }

                                            $(".read-content .read-contentr").append(str);
                                            novelJS.common.setTitle(res.data.meta_title);
                                            novelJS.common.changeURL(res.data.path,res.data.chapter_title+'-Flying-lines');
                                            novelJS.read.status.neid=res.data.nextChapterId;
                                            novelJS.read.status.allowGetChapter=true;
                                        },
                                        error:function (res) {
                                        }  
                                    });
                                },1000)
                            }
                        })  
                    }          
                }
            }

            
            
        });     
    },
    checkWindowSiz:function(){
        let lishishi=$(window).height();
        $('.read-contentl').css('height',lishishi+'px');
        $('.readolnum').css('height',lishishi-315+'px')
        $('.small-nav .chapter-list').css('height',lishishi-315+'px')
        $(window).resize(function(){
            let lishishi=$(window).height();
            $('.read-contentl').css('height',lishishi+'px');
            $('.readolnum').css('height',lishishi-315+'px')
            $('.small-nav .chapter-list').css('height',lishishi-315+'px')
            if($(window).width()<1190){
                $('.read-contentr-botbac').css('display','none')
            }else{
                $('.read-contentr-botbac').css('display','block')
            }
        })
    },
    disableCopy:function(){
        document.onselectstart = new Function ("return false");
        document.oncontextmenu = new Function ("return false");
        if (window.sidebar) {
            document.onmousedown = disableSelect();
            document.onclick = reEnable();
        }

        function disableSelect() {
            return false;
        }

        function reEnable() {
            return true;
        }
    },
    foldNav: function () {
        var $btn=$('.btn-fold');
        var $smallNav=$('.read-contentl .small-nav');
        var $bigNav=$('.read-contentl .big-nav');
        var navStatus = novelJS.common.getCookie('navListStatus')

        if(navStatus === 'isSmall'){
            $btn.removeClass('isBig');
            $bigNav.hide();
            $smallNav.show();
        }

        $btn.on('click',function () {
            var $this=$(this);

            if($this.hasClass('isBig')){
                $this.removeClass('isBig');
                $bigNav.hide();
                $smallNav.show();
                novelJS.common.setCookie('navListStatus','isSmall',30)
            }else {
                $this.addClass('isBig');
                $bigNav.show();
                $smallNav.hide();
                novelJS.common.setCookie('navListStatus','isBig',30)
            }
        })
    }
}