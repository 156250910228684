/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._uData = function () {};

novelAjax._uData.prototype.readHistory = function(opt){
    var url=`/user/${opt.user}/reading_history`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._uData.prototype.collection = function(opt){
    var url=`/user/${opt.user}/collection`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._uData.prototype.wish = function(opt){
    var url=`/user/${opt.user}/wishes`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._uData.prototype.record = function (opt) {
    var url=`/user/${opt.user}/comments_record`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax.uData = new novelAjax._uData();
