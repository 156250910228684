/**
 * Created by Administrator on 2018/7/23.
 */


axios.defaults.withCredentials=true;
novelJS.axInstance = axios.create({
    headers:{
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials' : 'true',
        'Access-Control-Allow-Methods' : '*',
        'Access-Control-Allow-Headers' : '*'
    }
});

var NovelHttp = function(url,method,opt) {
    this.url = url;
    this.method = method;
    this.opt = opt;
    return this.run();
};

NovelHttp.prototype.run = function() {
    var opt = this.opt || {};
    var successFn = typeof opt.success == 'function' ? opt.success: $.noop;
    var errorFn = typeof opt.error == 'function' ? opt.error: $.noop;
    if(this.method === 'get') opt.data = null;
    return novelJS.axInstance({
        url: this.url,
        method: this.method,
        data: opt.data
    }).then(successFn).catch(errorFn)
};
