/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._uDo = function () {};

novelAjax._uDo.prototype.readChapter = function(opt){
    var url='/user/reading_history';
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._uDo.prototype.getWonder = function (opt) {
    var url = '/user/wonder';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uDo.prototype.collection = function(opt){
    var url='/user/collection';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uDo.prototype.novelScore = function(opt){
    var url='/novel/score';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uDo.prototype.comment = function(opt){
    var url='/user/comment';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uDo.prototype.novelVote = function(opt){
    var url='/comment_vote/'+opt.comment_id;
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax.uDo = new novelAjax._uDo();
