/**
 * Created by Administrator on 2018/7/27.
 */

novelAjax._other = function () {};

novelAjax._other.prototype.getChapter = function(opt){
    var url = `/chapter/${opt.chapter_id}`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._other.prototype.getComments = function(opt){
    var url = '/comments';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._other.prototype.getChapList= function(opt){
    var url = `/novel/catalog/${opt.novel_id}`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._other.prototype.verifyRobot= function(opt){
    var url = `/chapter/verify/${opt.recaptchaStr}`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax.other = new novelAjax._other();
