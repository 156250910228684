/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._uForget = function () {};

novelAjax._uForget.prototype.sendEmail = function(opt){
    var url='/password/email';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uForget.prototype.verify = function (opt) {
    var url='/user/email_verify';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax._uForget.prototype.reset = function(opt){
    var url='/password/reset';
    var method = 'post';
    return new NovelHttp(url,method,opt);
};

novelAjax.uForget = new novelAjax._uForget();
