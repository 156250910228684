novelJS.column={
    init:function(){
        // novelJS.column.changecolumn();
        // novelJS.column.backTop();
    },
    backTop:function(){
        $('.head-fly-font').click(function(){
            $('.head-fly-font p').addClass('p-gray');
        })
    }
}