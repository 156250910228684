/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._msg = function () {

};

novelAjax._msg.prototype.getList = function (opt) {
    var url = `/user/notifications/${opt.type}?page_num=${opt.page_num}&page=${opt.page}`;
    var method='get';
    return new NovelHttp(url,method,opt)
};


novelAjax._msg.prototype.getCount = function (opt) {
    var url = `/user/notifications/${opt.type}/stats/`;
    var method = 'get';
    return new NovelHttp(url,method,opt);
};

novelAjax._msg.prototype.changeStatus = function (opt) {
    var url = `/user/notifications/${opt.type}/read/`;
    var method = 'patch';
    return new NovelHttp(url,method,opt)
};

novelAjax.msg = new novelAjax._msg();