/*!
 * Created by JKH on 2016/7/22.
 *
 * warning:This file in the last one when the mergers.
 */
// 18
$(function () {
    setTimeout(function(){
        /**
         *
         * @param {element} el  Feature detection
         * @param {function} fn  Executive function
         */
        function init(el,fn){
            if($(el).length && (typeof fn) === 'function') fn(el);
        }



        //tab切换
        init('.details-conrig-about',novelJS.details.init)
        init('.column-content',novelJS.column.init)
        init('.read-content',novelJS.read.init)
        init('.details-bookfr',novelJS.details.shareBook);
    },10);

});
