/**
 * Created by Administrator on 2018/7/23.
 */

novelAjax._nvSearch = function () {

};

novelAjax._nvSearch.prototype.getTips = function (opt) {
    var url = '/novels/ajax_search';
    var method = 'post';
    return new NovelHttp(url,method,opt)
};

novelAjax.nvSearch = new novelAjax._nvSearch();